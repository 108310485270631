import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import NumericFormatKr from './NumericFormatKr';
import { NumericFormat } from 'react-number-format';
import { Typography } from '@mui/material';
import React from 'react';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#ff3f5b',
    },
  },
});

const PrettoSlider = styled(Slider)({
  color: '#ff6384',
})


const NumericFormatPct = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      allowNegative={false}
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator=" "
      suffix={' %'}
    />
  );
});

/*


*/


function App() {
  const [lan, setLan] = useState(4000000)
  const [ranta, setRanta] = useState(4)

  const formatNum = (input) => {
    const r = Math.round(input)
    let nf = new Intl.NumberFormat('sv-SE');
    return nf.format(r)
  }

  const getTaxYear = () => {
    const rkr = lan * ranta / 100
    var avdrag = 0
    if (rkr <= 100000) {
      avdrag = rkr * 0.3
    } else {
      avdrag = 100000 * 0.3 + (rkr - 100000) * 0.21
    }
    return formatNum(avdrag)
  }

  const getTaxMonth = () => {
    const rkr = lan * ranta / 100
    var avdrag = 0
    if (rkr <= 100000) {
      avdrag = rkr * 0.3
    } else {
      avdrag = 100000 * 0.3 + (rkr - 100000) * 0.21
    }
    return formatNum(avdrag / 12)
  }

  const handleLanChange = (event) => {
    setLan(event.target.value);
  }

  const handleRChange = (event) => {
    setRanta(event.target.value);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container p={1}>
        <Grid item xs={6} p={1}>
          <Slider
            sx={{
              '& input[type="range"]': {
              },
            }}
            value={lan}
            onChange={handleLanChange}
            aria-label="Lån"
            valueLabelDisplay="auto"
            min={0}
            step={20000}
            max={20000000}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <TextField

            label="Lån"
            fullWidth
            //            InputProps={{ inputComponent: NumericFormatKr, }}
            value={lan}
            onChange={(event) => setLan(event.target.value)}
          />
        </Grid>
        <Grid item xs={6} p={1}>
          <Slider
            sx={{
              '& input[type="range"]': {
              },
            }}
            value={ranta}
            onChange={handleRChange}
            aria-label="Ränta"
            valueLabelDisplay="auto"
            min={0}
            step={0.25}
            max={10}
          />
        </Grid>

        <Grid item xs={6} p={1}>
          <TextField
            label="Ränta"
            fullWidth
            //InputProps={{ inputComponent: NumericFormatPct, }}
            value={ranta}
            onChange={(event) => setRanta(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} p={1}>
          <Typography>
            Ränteavdrag per år {getTaxYear()}
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Typography>
            Ränteavdrag per månad {getTaxMonth()}
          </Typography>
        </Grid>
      </Grid>

    </ThemeProvider >
  );
}

export default App;
